@import "../toolkit/vars";
.cc-banner.cc-bottom {
  z-index: 999999999;
  box-sizing: border-box;
}

.cc-window {
  opacity: 1;
  transition: opacity 1s ease;
  background-color: $color-masthead;
  border-top: 6px solid $color-brand-2;
  color: $color-off-white;
  font-family: "freight-sans-pro", sans-serif;
  &.cc-invisible {
    opacity: 0;
  }
  p {
    margin-top: 0;
  }
  h2 {
    margin-top: 0;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "freight-big-pro", "freight-display-pro", "freight-text-pro", sans-serif;
    letter-spacing: 1px;
    color: #fff;
  }
  @media (max-width: 1039px) {
    text-align: left;
    h2 {
      margin-bottom: 12px;
    }
  }
}

.cc-revoke.cc-bottom {
  display: none;
}

.cc-animate.cc-revoke {
  transition: transform 1s ease;
  &.cc-top {
    transform: translateY(-2em);
  }
  &.cc-bottom {
    transform: translateY(2em);
  }
  &.cc-active {
    &.cc-bottom,
    &.cc-top {
      transform: translateY(0);
    }
  }
}

// padding
.cookie-consent,
.cookie-heading,
.settings-heading,
.settings-form {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

// Heading
.cookie-heading,
.settings-heading {
  float: left;
  width: calc( 100% / 12 * 2);
  @media( max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media( max-width: 1039px) {
    width: 100%;
  }
}

.cookie-heading,
.settings-heading {
  text-align: right;
  @media (max-width: 1039px) {
    text-align: left;
    width: 100%;
  }
}

.cookie-heading+div {
  @media (max-width: 1039px) {
    text-align: left;
    width: 100%;
  }
}

// Consent
.cookie-consent {
  float: left;
  width: calc( 100% / 12 * 8);
}

// Settings form
.settings-form {
  float: left;
  width: calc(100% / 12 * 10);
  @media( max-width: 1039px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.cc-revoke:hover {
  transform: translateY(0);
}

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
}

.cc-link {
  text-decoration: underline;
}

.cc-revoke {
  &:hover {
    text-decoration: underline;
  }
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  z-index: 9999;
}

.cc-window {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  z-index: 9999;
  &.cc-static {
    position: static;
  }
  &.cc-floating {
    padding: 2em;
    width: 100% !important;
    bottom: 0 !important;
  }
  &.cc-banner {
    padding: 2em 2.4em;
    width: 100% !important;
    @media( max-width: 768px) {
      padding: 3rem 1rem;
    }
  }
}

.cc-revoke {
  padding: 0.5em;
}

.cc-header {
  font-size: 18px;
  font-weight: 700;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: 0.8;
  color: $color-off-white;
  &:hover {
    opacity: 1;
    color: $color-brand-2;
  }
  &:active,
  &:visited {
    color: $color-off-white;
    &:hover {
      opacity: 1;
      color: $color-brand-2;
    }
  }
}

.cc-btn {
  display: block;
  font-size: 24px;
  text-decoration: underline;
  margin-bottom: 6px;
}

.cc-banner .cc-btn:last-child {
  min-width: 140px;
}

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent;
  &:focus,
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;
  line-height: 0.75;
  &:focus,
  &:hover {
    opacity: 1;
  }
}

.cc-revoke {
  &.cc-top {
    top: 0;
    left: 3em;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
  &.cc-bottom {
    bottom: 0;
    left: 3em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  &.cc-left {
    left: 3em;
    right: unset;
  }
  &.cc-right {
    right: 3em;
    left: unset;
  }
}

.cc-top {
  top: 1em;
}

.cc-left {
  left: 1em;
}

.cc-right {
  right: 1em;
}

.cc-bottom {
  bottom: 1em;
}

.cc-floating {
  >.cc-link {
    margin-bottom: 1em;
  }
  .cc-message {
    display: block;
    margin-bottom: 1em;
  }
}

.cc-banner {
  &.cc-top {
    left: 0;
    right: 0;
    top: 0;
  }
  &.cc-bottom {
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.cc-compliance {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-height: 1px;
  width: 100%;
  float: left;
  box-sizing: border-box;
  >.cc-btn {
    color: $color-off-white;
    border: 0;
    &:hover {
      color: $color-brand-2;
    }
  }
  >.cc-settings {
    color: $color-off-white;
    border: 0;
    display: block;
    opacity: 0.8;
    text-decoration: underline;
    &:hover {
      color: $color-brand-2;
    }
  }
  @media (min-width: 1039px) {
    width: 16.66667%;
    float: left;
  }
  @media (max-width: 1039px) {
    margin-top: 12px;
    a {
      display: inline-block;
      &:first-child {
        padding-right: 12px;
      }
      &:last-child {
        padding-left: 0;
        border-left: none;
      }
    }
  }
}

.cc-btn+.cc-btn {
  margin-left: 0.5em;
}

@media print {
  .cc-revoke,
  .cc-window {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait),
screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window {
    &.cc-top {
      top: 0;
    }
    &.cc-bottom {
      bottom: 0;
    }
    &.cc-banner,
    &.cc-left,
    &.cc-right {
      left: 0;
      right: 0;
    }
    .cc-message {
      margin-bottom: 1em;
    }
  }
}

.cc-floating {
  &.cc-theme-classic {
    padding: 1.2em;
    border-radius: 5px;
  }
  &.cc-type-info.cc-theme-classic .cc-compliance {
    text-align: center;
    display: inline;
  }
}

.cc-theme-classic .cc-btn {
  border-radius: 5px;
  &:last-child {
    min-width: 140px;
  }
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block;
}

.cc-theme-edgeless.cc-window {
  padding: 0;
}

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em 2em 1.5em;
}

.cc-banner.cc-theme-edgeless {
  .cc-btn {
    margin: 0;
    padding: 0.8em 1.8em;
    height: 100%;
  }
  .cc-message {
    margin-left: 1em;
  }
}

.cc-floating.cc-theme-edgeless .cc-btn+.cc-btn {
  margin-left: 0;
}

/*
 * Floating override
 */

.cc-floating {
  max-width: 100%;
  width: 100%;
}

// Dismiss (no X)
.cc-dismiss::after {
  display: none;
}

/*
 * Cookie Settings
 */

.settings-dismiss {
  background-color: $color-brand-2 !important;
  color: #fff !important;
  cursor: pointer;
  margin-bottom: 8px;
  display: inline-block;
  box-sizing: border-box;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  font-family: inherit;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  padding: 12px;
  &:hover {
    background-color: $color-brand-2-dark !important;
  }
}

.cookie-settings {
  display: none;
  .icon-gold {
    color: $color-brand-2;
    font-size: 36px;
    line-height: 30px;
  }
  .cc-dismiss::after {
    display: none;
  }
  .settings-form {
    padding-left: 24px;
    h2 {
      margin-bottom: 6px;
      .tk-icon {
        margin-top: -4px;
      }
    }
    .settings-indiv {
      padding-left: 72px;
      .checkbox label {
        min-height: 22px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
        position: relative;
      }
      .checkbox input {
        position: absolute;
        margin-left: -20px;
        margin-top: 5px;
        margin-right: 4px;
        margin-bottom: 8px;
        border: 0;
      }
      .checkbox:last-child {
        margin-bottom: 0;
      }
      @media (max-width: 767px) {
        padding-left: 0;
      }
    }
    @media (max-width: 767px) {
      padding-left: 10px;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin-bottom: -6px;
    margin-right: 6px;
  }
  .switch input {
    display: none;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  input:checked+.slider {
    background-color: $color-brand-2;
  }
  input:focus+.slider {
    box-shadow: 0 0 1px $color-brand-2;
  }
  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}