/*------------------------------------*   $CUSTOM TOOLKIT VARIABLES
\*------------------------------------*/
/**
 * Place any custom toolkit variables here.
 * N.B. dont use sass darken or lighten as we
 * find and replace colors HEX's for theming
 */
/* For quick reference breakpoints
 * $screen-xs:                  480px;
 * $screen-sm:                  768px;
 * $screen-md:                  1024px;
 * $screen-lg:                  1260px;
 */
/**
 * UI colours
 */
/**
 * Layout
 * Wrappers & widths
 */
/**
 * Bootstrap Mid-Small - col-ms-* - the missing grid set for Bootstrap3.
 * This is a hack to fill the gap between 480 and 767 pixels. See _boostrap-ms.scss
 */
/**
 * Screen xxs max, do queries don't overlap
 */
/**
 * Old Engineering wrapper, just for reference
 * $wrapper-xs: 660px;
 * $wrapper-sm: 768px;
 * $wrapper-md: 992px;
 * $wrapper-lg: 1200px;
 * $wrapper-xl: 1260px;
 * xs: 660px;
 * sm: 768px;
 * md: 992px;
 * lg: 1260px;
 */
/**
 * Spacing
 */
/**
 * REM spacing
 * Not currently in use, start using when ready
 */
/**
 * Typography
 */
/*------------------------------------*   $BOOTSTRAP OVERRIDEN VARIABLES
\*------------------------------------*/
/**
 * Overide the default bootsrap variables here
 */
.cc-banner.cc-bottom {
  z-index: 999999999;
  box-sizing: border-box; }

.cc-window {
  opacity: 1;
  transition: opacity 1s ease;
  background-color: #212121;
  border-top: 6px solid #916f40;
  color: #f7f7f7;
  font-family: "freight-sans-pro", sans-serif; }
  .cc-window.cc-invisible {
    opacity: 0; }
  .cc-window p {
    margin-top: 0; }
  .cc-window h2 {
    margin-top: 0;
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 16px; }
  .cc-window h1,
  .cc-window h2,
  .cc-window h3,
  .cc-window h4,
  .cc-window h5,
  .cc-window h6 {
    font-family: "freight-big-pro", "freight-display-pro", "freight-text-pro", sans-serif;
    letter-spacing: 1px;
    color: #fff; }
  @media (max-width: 1039px) {
    .cc-window {
      text-align: left; }
      .cc-window h2 {
        margin-bottom: 12px; } }

.cc-revoke.cc-bottom {
  display: none; }

.cc-animate.cc-revoke {
  transition: transform 1s ease; }
  .cc-animate.cc-revoke.cc-top {
    transform: translateY(-2em); }
  .cc-animate.cc-revoke.cc-bottom {
    transform: translateY(2em); }
  .cc-animate.cc-revoke.cc-active.cc-bottom, .cc-animate.cc-revoke.cc-active.cc-top {
    transform: translateY(0); }

.cookie-consent,
.cookie-heading,
.settings-heading,
.settings-form {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box; }

.cookie-heading,
.settings-heading {
  float: left;
  width: calc( 100% / 12 * 2); }
  @media (max-width: 767px) {
    .cookie-heading,
    .settings-heading {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (max-width: 1039px) {
    .cookie-heading,
    .settings-heading {
      width: 100%; } }

.cookie-heading,
.settings-heading {
  text-align: right; }
  @media (max-width: 1039px) {
    .cookie-heading,
    .settings-heading {
      text-align: left;
      width: 100%; } }

@media (max-width: 1039px) {
  .cookie-heading + div {
    text-align: left;
    width: 100%; } }

.cookie-consent {
  float: left;
  width: calc( 100% / 12 * 8); }

.settings-form {
  float: left;
  width: calc(100% / 12 * 10); }
  @media (max-width: 1039px) {
    .settings-form {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px; } }

.cc-revoke:hover {
  transform: translateY(0); }

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s; }

.cc-link {
  text-decoration: underline; }

.cc-revoke {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  z-index: 9999; }
  .cc-revoke:hover {
    text-decoration: underline; }

.cc-window {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  z-index: 9999; }
  .cc-window.cc-static {
    position: static; }
  .cc-window.cc-floating {
    padding: 2em;
    width: 100% !important;
    bottom: 0 !important; }
  .cc-window.cc-banner {
    padding: 2em 2.4em;
    width: 100% !important; }
    @media (max-width: 768px) {
      .cc-window.cc-banner {
        padding: 3rem 1rem; } }

.cc-revoke {
  padding: 0.5em; }

.cc-header {
  font-size: 18px;
  font-weight: 700; }

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
  cursor: pointer; }

.cc-link {
  opacity: 0.8;
  color: #f7f7f7; }
  .cc-link:hover {
    opacity: 1;
    color: #916f40; }
  .cc-link:active, .cc-link:visited {
    color: #f7f7f7; }
    .cc-link:active:hover, .cc-link:visited:hover {
      opacity: 1;
      color: #916f40; }

.cc-btn {
  display: block;
  font-size: 24px;
  text-decoration: underline;
  margin-bottom: 6px; }

.cc-banner .cc-btn:last-child {
  min-width: 140px; }

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent; }
  .cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
    background-color: transparent;
    text-decoration: underline; }

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;
  line-height: 0.75; }
  .cc-close:focus, .cc-close:hover {
    opacity: 1; }

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em; }

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em; }

.cc-revoke.cc-left {
  left: 3em;
  right: unset; }

.cc-revoke.cc-right {
  right: 3em;
  left: unset; }

.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 1em; }

.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-compliance {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-height: 1px;
  width: 100%;
  float: left;
  box-sizing: border-box; }
  .cc-compliance > .cc-btn {
    color: #f7f7f7;
    border: 0; }
    .cc-compliance > .cc-btn:hover {
      color: #916f40; }
  .cc-compliance > .cc-settings {
    color: #f7f7f7;
    border: 0;
    display: block;
    opacity: 0.8;
    text-decoration: underline; }
    .cc-compliance > .cc-settings:hover {
      color: #916f40; }
  @media (min-width: 1039px) {
    .cc-compliance {
      width: 16.66667%;
      float: left; } }
  @media (max-width: 1039px) {
    .cc-compliance {
      margin-top: 12px; }
      .cc-compliance a {
        display: inline-block; }
        .cc-compliance a:first-child {
          padding-right: 12px; }
        .cc-compliance a:last-child {
          padding-left: 0;
          border-left: none; } }

.cc-btn + .cc-btn {
  margin-left: 0.5em; }

@media print {
  .cc-revoke,
  .cc-window {
    display: none; } }

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0; }
  .cc-window.cc-bottom {
    bottom: 0; }
  .cc-window.cc-banner, .cc-window.cc-left, .cc-window.cc-right {
    left: 0;
    right: 0; }
  .cc-window .cc-message {
    margin-bottom: 1em; } }

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }
  .cc-theme-classic .cc-btn:last-child {
    min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block; }

.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em 2em 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: 0.8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

/*
 * Floating override
 */
.cc-floating {
  max-width: 100%;
  width: 100%; }

.cc-dismiss::after {
  display: none; }

/*
 * Cookie Settings
 */
.settings-dismiss {
  background-color: #916f40 !important;
  color: #fff !important;
  cursor: pointer;
  margin-bottom: 8px;
  display: inline-block;
  box-sizing: border-box;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  font-family: inherit;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 12px; }
  .settings-dismiss:hover {
    background-color: #916f40 !important; }

.cookie-settings {
  display: none;
  /* Rounded sliders */ }
  .cookie-settings .icon-gold {
    color: #916f40;
    font-size: 36px;
    line-height: 30px; }
  .cookie-settings .cc-dismiss::after {
    display: none; }
  .cookie-settings .settings-form {
    padding-left: 24px; }
    .cookie-settings .settings-form h2 {
      margin-bottom: 6px; }
      .cookie-settings .settings-form h2 .tk-icon {
        margin-top: -4px; }
    .cookie-settings .settings-form .settings-indiv {
      padding-left: 72px; }
      .cookie-settings .settings-form .settings-indiv .checkbox label {
        min-height: 22px;
        padding-left: 20px;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
        position: relative; }
      .cookie-settings .settings-form .settings-indiv .checkbox input {
        position: absolute;
        margin-left: -20px;
        margin-top: 5px;
        margin-right: 4px;
        margin-bottom: 8px;
        border: 0; }
      .cookie-settings .settings-form .settings-indiv .checkbox:last-child {
        margin-bottom: 0; }
      @media (max-width: 767px) {
        .cookie-settings .settings-form .settings-indiv {
          padding-left: 0; } }
    @media (max-width: 767px) {
      .cookie-settings .settings-form {
        padding-left: 10px; } }
  .cookie-settings .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
    margin-bottom: -6px;
    margin-right: 6px; }
  .cookie-settings .switch input {
    display: none; }
  .cookie-settings .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s; }
  .cookie-settings .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 6px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s; }
  .cookie-settings input:checked + .slider {
    background-color: #916f40; }
  .cookie-settings input:focus + .slider {
    box-shadow: 0 0 1px #916f40; }
  .cookie-settings input:checked + .slider:before {
    transform: translateX(26px); }
  .cookie-settings .slider.round {
    border-radius: 34px; }
  .cookie-settings .slider.round:before {
    border-radius: 50%; }
